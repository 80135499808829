import React from 'react';
import Popconfirm from '../Feedback/Popconfirm';

export default function({ index, onDeleteCell }) {
  return (
    <Popconfirm
      title="Confirma a exclusão?"
      okText="EXCLUIR"
      cancelText="Não"
      onConfirm={() => onDeleteCell(index)}
    >
      <a href="# ">Excluir</a>
    </Popconfirm>
  );
}
